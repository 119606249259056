export const SNAME = {
  // LOCAL STORAGE KEYS
  SKIP_WEBAUTHN_TRUST_COUNTER: 'skipWebAuthnTrustCounter',
  // SESSION STORAGE KEYS
  MERCHANT: 'merchantReferrer',
  OIDCCODE: 'oidccode',
  TEMP_EMAIL: 'tempemail',
  TMX_SESSION_ID: 'TMX_SESSION_ID',
  CORRELATIONID: 'correlationId',
  WEBAUTHNTRUSTED: 'webauthnTrusted',
  DEVICE_ID: 'device_id',
  ACCEPT_COOKIES: 'accept_cookies',
  ACCEPT_NONESSENTIAL: 'accept_nonessential',
  SET_DEVICE_VISITED: 'set_device_visited',
  PERSONAL_DETAILS: 'personal_details',
  AUTH_METHOD: 'auth_method',
  FORCE_SIGNOUT: 'forceSignout',
  OTC_VERIFIED: 'otc_verified',
  ACCEPT_PRIVACY: 'accept_privacy',
  USER_REGISTRATION_SUCCESS: 'user_registration_success',
  FI_ID: 'fiId',
  // debugger

  USER_JWS: 'USER_JWS',
  ADDRESS_CHANGED: 'ADDRESS_CHANGED',
  SPLASH: 'SPLASH',
};
