/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.1
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserAuthMethod } from './userAuthMethod';

/**
 * Customer information available to KONEK when a consent is granted by the user
 */
export interface CustomerInfo {
  /**
   * KONEK assigned unique identifier of the customer
   */
  user_profile_ref: string;
  /**
   * Uniquely identifying value of the FI customer, copied from ID-Token.sub claim provided by FI.   **NOTE:**   - In the case of Consent authorization request, the value represents the holder of card/account selected for the checkout.
   */
  fi_user_ref: string;
  /**
   * The `fi_user_ref` of the Authenticated User.   **CONDITIONS:**   - Note that if the authenticated user does not belong to the FI providing Consent approval, the value will not be provided.
   */
  fi_user_ref_auth?: string;
  auth_method: UserAuthMethod;
  /**
   * Specifies the type of  user within KONEK system, valid values are;   `GUEST` - Customer chose to proceed as guest without creating profile. In this case, `auth_method` field must be `FI_AUTH`   `REGISTERED` - Customer is a registered user within KONEK.
   */
  user_type: CustomerInfo.UserTypeEnum;
  /**
   * Specifies the last profile activity of the customer i.e the last time consent was provided. This value should be only provided for the FI
   */
  last_profile_activity?: string;
  /**
   * Specifies the time when the user profile was created. This value should be only provided for the FI
   */
  user_profile_creation_date?: string;
  /**
   * Defines the dat/time of when was the last update on the shipping address for a specific profile. This value should be only provided for the FI
   */
  last_profile_shipping_address_change_date?: string;
  /**
   * Specifies user selected `fi_account_ref` for default account. This value is provided only if the default fi_account_ref belongs to the FI being requested for consent authorization.
   */
  default_fi_account_ref?: string;
  /**
   * FI assigned unique identifier of the customer for Interac system. This field is conditionally required for account based payments. If this field is not present in the Consent Auth request for Interac Direct transactions(Account Based payments). This Value needs to be provided by the FI as part of the `SecuredPaymentConsent.Debtor.CustomerInfo`
   */
  interac_participant_user_id?: string;
}
export namespace CustomerInfo {
  export type UserTypeEnum = 'GUEST' | 'REGISTERED';
  export const UserTypeEnum = {
    Guest: 'GUEST' as UserTypeEnum,
    Registered: 'REGISTERED' as UserTypeEnum,
  };
}
