import { ConsentGrantResponse } from '@api/model/consentGrantResponse';
import { ConsentGrantRequest } from '@api/model/consentGrantRequest';
import { createAction, props } from '@ngrx/store';
import { Consent } from './checkout.interface';
import { ShippingAddress } from '@api/model/shippingAddress';
import { SupportedBy } from '@core/constants';
import { OrderPayment } from '@api/model/orderPayment';
import { ConsentStepUpAuthRequest } from '@api/model/consentStepUpAuthRequest';
import { ConsentStatus } from '@api/model/consentStatus';
import { HttpErrorResponse } from '@angular/common/http';
import { IDVSTATUS, STEPUP_ERROR_CODE } from '@core/constants/error-code';

export const setpaymentMethodsNotSupportedBy = createAction(
  '[CHECKOUT] SET_PAYMENT_METHOD_NOT_SUPPORTED_BY',
  props<{ paymentMethodsNotSupportedBy: SupportedBy }>()
);
export const setConsentId = createAction('[CHECKOUT] SET_CONSENT_ID', props<{ consentId: string }>());

export const checkoutInit = createAction('[CHECKOUT] INIT');
export const keepAlive = createAction('[CHECKOUT] KEEP_ALIVE');
export const checkoutConfirmCancel = createAction('[CHECKOUT] CONFIRM CANCEL');
export const checkoutCancel = createAction('[CHECKOUT] CANCEL');
export const getConsentStart = createAction('[CHECKOUT] GET_CONSENT_START');
export const getConsentFailed = createAction(
  '[CHECKOUT] GET_CONSENT_FAILED',
  props<{ error: Error | HttpErrorResponse }>()
);
export const getConsentSuccess = createAction('[CHECKOUT] GET_CONSENT_SUCCESS', props<{ consent: Consent }>());
export const setShippingAddress = createAction(
  '[CHECKOUT] SET_SHIPPING_ADDRESS',
  props<{ shippingAddress: ShippingAddress; update: boolean }>()
);

export const setPaymentMethod = createAction('[CHECKOUT] SET_PAYMENT_METHOD', props<{ paymentMethod: string }>());
export const updateConsentGrant = createAction(
  '[CHECKOUT] UPDATE_CONSENT_GRANT',
  props<{ consentGrantPayload: Partial<ConsentGrantRequest>; clear?: boolean }>()
);

export const postConsentGrantStart = createAction('[CHECKOUT] POST_CONSENT_GRANT_START');

export const postConsentGrantSuccess = createAction(
  '[CHECKOUT] POST_CONSENT_GRANT_SUCCESS',
  props<{ consentGrantResponse: Partial<ConsentGrantResponse>; fi_user_id?: string }>()
);

export const postConsentGrantComplete = createAction('[CHECKOUT] POST_CONSENT_GRANT_COMPLETE');
export const postConsentGrantFailed = createAction('[CHECKOUT] POST_CONSENT_GRANT_FAILED', props<{ error: Error }>());

export const postConsentGrantCompleteStepUp = createAction(
  '[CHECKOUT] POST_CONSENT_GRANT_COMPLETE_STEPUP',
  props<{ consentId: string; payload: ConsentStepUpAuthRequest }>()
);
export const postConsentGrantCompleteStepUpSuccess = createAction(
  '[CHECKOUT] POST_CONSENT_GRANT_COMPLETE_STEPUP_SUCCESS',
  props<{ status: ConsentStatus }>()
);
export const postConsentGrantCompleteStepUpFailed = createAction(
  '[CHECKOUT] POST_CONSENT_GRANT_COMPLETE_STEPUP_FAILED'
);

export const getShippingCost = createAction(
  '[CHECKOUT] GET_SHIPPING_COST',
  props<{ shippingAddress: ShippingAddress }>()
);
export const getShippingCostFailed = createAction('[CHECKOUT] GET_SHIPPING_COST_FAILED', props<{ error: Error }>());
export const getShippingCostSuccess = createAction(
  '[CHECKOUT] GET_SHIPPING_COST_SUCCESS',
  props<{ shippingCost: OrderPayment }>()
);

export const getShippingCostshippingCostFreeze = createAction('[CHECKOUT] GET_SHIPPING_COST_FREEZE');
export const pushGoogleTags = createAction('[CHECKOUT] PUSH_GOOGLE_TAGS');
export const setStepUpCode = createAction('[CHECKOUT] SET_STEPUP_CODE', props<{ stepUpCode: STEPUP_ERROR_CODE }>());
export const clearStepUpCode = createAction('[CHECKOUT] CLEAR_STEPUP_CODE');
export const idvVerificationStart = createAction('[CHECKOUT] IDV_VERIFICATION_START');
export const setIdvVerificationStatus = createAction('[CHECKOUT] SET_IDV_STATUS', props<{ idvStatus: IDVSTATUS }>());
