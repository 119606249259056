/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.1
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type ContactFields = 'NAME' | 'EMAIL' | 'PHONE' | 'SHIPPING_ADDRESS' | 'IDV_AGE' | 'IDV_RESIDENCE_POSTAL_CODE';

export const ContactFields = {
  Name: 'NAME' as ContactFields,
  Email: 'EMAIL' as ContactFields,
  Phone: 'PHONE' as ContactFields,
  ShippingAddress: 'SHIPPING_ADDRESS' as ContactFields,
  IdvAge: 'IDV_AGE' as ContactFields,
  IdvResidencePostalCode: 'IDV_RESIDENCE_POSTAL_CODE' as ContactFields,
};
