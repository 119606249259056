import { HttpErrorResponse } from '@angular/common/http';
import {
  AddressListResponse,
  DeviceDetails,
  ShippingAddress,
  UpdateDevice,
  UserProfile,
  UserRegistrationStatus,
  UserRegistrationVerificationResponse,
} from '@api/index';
import { createAction, props } from '@ngrx/store';
import { Notices } from '@store/core/core.interface';
import {
  EmailRegistrationStatus,
  EmailVerificationStatus,
  ILinkedFI,
  IUserProfile,
  PaymentMethodCheckStatus,
} from './user.interface';
import { PbbHttpErrorResponse } from '@core/constants/error-code';

export const UserActions = {
  setUserProfileRef: createAction('[USER] SET_USER_PROFILE_REF', props<{ userProfileRef: string }>()),
  setFiUserId: createAction('[USER] SET_FI_USER_ID', props<{ fiUserId: string }>()),
  getUserProfileRef: createAction('[USER] GET_USER_PROFILE_REF'),
  getUserProfile: createAction('[USER] GET_USER_PROFILE'),
  signout: createAction('[USER] SIGNOUT_USER'),
  getUserProfileSuccess: createAction('[USER] GET_USER_PROFILE_SUCCESS', props<{ userProfile: IUserProfile }>()),
  getUserProfileFailed: createAction('[USER] GET_USER_PROFILE_FAILED', props<{ error: Error | HttpErrorResponse }>()),
  verifyUserRegistration: createAction('[USER] VERIFY_USER_REGISTRATION'),

  verifyUserRegistrationSuccess: createAction(
    '[USER] VERIFY_USER_REGISTRATION_SUCCESS',
    props<{ userRegistrationResponse: UserRegistrationVerificationResponse }>()
  ),
  verifyUserRegistrationFailed: createAction(
    '[USER] VERIFY_USER_REGISTRATION_FAILED',
    props<{ error: Error | HttpErrorResponse }>()
  ),
  updateUserProfilePersonalDetail: createAction(
    '[USER] UPDATE_USER_PROFILE_PERSONAL_DETAIL',
    // eslint-disable-next-line @ngrx/prefer-inline-action-props
    props<{ userPersonalInfo: Partial<IUserProfile> }>()
  ),
  updateUserProfileStart: createAction(
    '[USER] UPDATE_USER_PROFILE_START',
    props<{ userProfile: IUserProfile; notice?: Notices }>()
  ),
  updateUserProfile: createAction('[USER] UPDATE_USER_PROFILE', props<{ partialUserProfile: Partial<IUserProfile> }>()),
  updateUserProfileSuccess: createAction(
    '[USER] UPDATE_USER_PROFILE_SUCCESS',
    props<{ userProfile: IUserProfile; notice?: Notices }>()
  ),
  updateUserProfileFailed: createAction(
    '[USER] UPDATE_USER_PROFILE_FAILED',
    props<{ error: Error | HttpErrorResponse }>()
  ),

  // PAYMENTS
  noAcceptedPayments: createAction('[USER] NO_ACCEPTED_PAYMENTS'),
  updatePaymentMethodsCheckStatus: createAction(
    '[USER] UPDATE_PAYMENT_METHOD_CHECK_STATUS',
    props<{ paymentMethodCheckStatus?: PaymentMethodCheckStatus }>()
  ),
  setAvailablePaymentMethods: createAction(
    '[USER] SET_AVAILABLE_PAYMENT_METHODS',
    props<{ linkedFiInfo: Array<ILinkedFI> }>()
  ),

  // SHIPPING ADDRESS
  setUserShippingAddressRef: createAction(
    '[USER] SET_USER_SHIPPING_ADDRESS_REF',
    props<{ shippingAddressRef: string }>()
  ),
  // ADD
  addShippingAddressStart: createAction(
    '[USER] ADD_SHIPPING_ADDRESS_START',
    props<{ shippingAddress: ShippingAddress }>()
  ),
  addShippingAddressSuccess: createAction(
    '[USER] ADD_SHIPPING_ADDRESS_SUCCESS',
    props<{ shippingAddress: ShippingAddress }>()
  ),
  addShippingAddressFailed: createAction('[USER] ADD_SHIPPING_ADDRESS_FAILED', props<{ error: Error }>()),
  // UPDATE
  updateShippingAddressStart: createAction(
    '[USER] UPDATE_SHIPPING_ADDRESS_START',
    props<{ shippingAddress: ShippingAddress; skipSave?: boolean }>()
  ),
  updateShippingAddressSuccess: createAction(
    '[USER] UPDATE_SHIPPING_ADDRESS_SUCCESS',
    props<{ shippingAddress: ShippingAddress }>()
  ),
  updateShippingAddressFailed: createAction('[USER] UPDATE_SHIPPING_ADDRESS_FAILED', props<{ error: Error }>()),
  // DELETE
  deleteShippingAddressStart: createAction('[USER] DELETE_SHIPPING_ADDRESS_START', props<{ addressIndex: number }>()),
  deleteShippingAddressSuccess: createAction(
    '[USER] DELETE_SHIPPING_ADDRESS_SUCCESS',
    props<{ shippingAddressRef: string }>()
  ),
  deleteShippingAddressFailed: createAction('[USER] DELETE_SHIPPING_ADDRESS_FAILED', props<{ error: Error }>()),
  modifyShippingAddressList: createAction('[USER] MODIFY_SHIPPING_ADDRESS_LIST'),

  modifyShippingAddressListSuccess: createAction('[USER] MODIFY_SHIPPING_ADDRESS_LIST_SUCCESS'),
  modifyShippingAddressListFailed: createAction('[USER] MODIFY_SHIPPING_ADDRESS_LIST_FAILED'),

  // SET DEFAULT
  setDefaultShippingAddressStart: createAction(
    '[USER] SET_DEFAULT_SHIPPING_ADDRESS_START',
    props<{ shippingAddressRef: string }>()
  ),
  setDefaultShippingAddressSuccess: createAction(
    '[USER] SET_DEFAULT_SHIPPING_ADDRESS_SUCCESS',
    props<{ shippingAddressRef: string }>()
  ),
  setDefaultShippingAddressFailed: createAction(
    '[USER] SET_DEFAULT_SHIPPING_ADDRESS_FAILED',
    props<{ error: Error }>()
  ),

  // SET DEFAULT PAYMENT METHOD
  setDefaultPaymentMethodStart: createAction(
    '[USER] SET_DEFAULT_PAYMENT_METHOD_START',
    props<{ paymentMethodRef: string; linkedFiRef: string | undefined }>()
  ),
  setDefaultPaymentMethodSuccess: createAction(
    '[USER] SET_DEFAULT_PAYMENT_METHOD_SUCCESS',
    props<{ paymentMethodRef: string; linkedFiRef: string | undefined }>()
  ),
  setDefaultPaymentMethodFailed: createAction('[USER] SET_DEFAULT_PAYMENT_METHOD_FAILED', props<{ error: Error }>()),

  // EMAIL VERIFICATION
  updateEmailVerificationStatus: createAction(
    '[USER] UPDATE_EMAIL_VERIFICATION_STATUS',
    props<{ status: EmailVerificationStatus }>()
  ),
  setEmailVerification: createAction('[USER] REQUIRE_EMAIL_VERIFICATION', props<{ email: string }>()),
  setEmailVerificationFailed: createAction(
    '[USER] REQUIRE_EMAIL_VERIFICATION_FAILED',
    props<{ error: PbbHttpErrorResponse }>()
  ),
  clearEmailVerification: createAction('[USER] CANCEL_EMAIL_VERIFICATION'),
  emailVerificationCheck: createAction(
    '[USER] EMAIL_VERIFICATION_CHECK',
    props<{ email: string; magic_ref: string; tokenExpiry: string | undefined }>()
  ),
  emailVerificationResume: createAction('[USER] EMAIL_VERIFICATION_RESUME'),
  emailVerified: createAction('[USER] EMAIL_VERIFIED'),

  // REGISTER
  postUserRegister: createAction('[USER] REGISTER', props<{ userInputs: UserProfile }>()),
  postUserRegisterSuccess: createAction('[USER] REGISTER_SUCCESS', props<{ userJWS: string }>()),
  setUserRegisteredStatus: createAction(
    '[USER] SET REGISTER STATE',
    props<{ userRegistrationStatus?: UserRegistrationStatus }>()
  ),
  checkEmailRegistrationStart: createAction('[USER] CHECK EMAIL REG START', props<{ email: string }>()),
  checkEmailRegistrationSuccess: createAction(
    '[USER] CHECK EMAIL REG SUCCESS',
    props<{ status: EmailRegistrationStatus }>()
  ),
  checkEmailRegistrationFailed: createAction(
    '[USER] CHECK EMAIL REG FAILED',
    props<{ error: Error | HttpErrorResponse }>()
  ),
  getShippingAddressesStart: createAction('[USER] GET_SHIPPING_ADDRESSES'),
  getShippingAddressesSuccess: createAction(
    '[USER] GET_SHIPPING_ADDRESSES_SUCCESS',
    props<{ addressListResponse: AddressListResponse }>()
  ),
  getShippingAddressesFailed: createAction(
    '[USER] GET_SHIPPING_ADDRESSES_FAILED',
    props<{ error: Error | HttpErrorResponse }>()
  ),
  unlinkFinancialInstituteStart: createAction(
    '[USER] UNLINK_FINANCIAL_INSTITUTE_START',
    props<{ linkedFiRef: string; fiUserId: string; signout?: boolean }>()
  ),
  unlinkFinancialInstituteSuccess: createAction(
    '[USER] UNLINK_FINANCIAL_INSTITUTE_SUCCESS',
    props<{ signout?: boolean }>()
  ),
  unlinkFinancialInstituteFailed: createAction(
    '[USER] UNLINK_FINANCIAL_INSTITUTE_FAILED',
    props<{ error: Error | HttpErrorResponse }>()
  ),
  //to be used for delete user profile
  deleteUserProfileStart: createAction('[USER] DELETE_USER_PROFILE_START'),
  deleteUserProfileSuccess: createAction('[USER] DELETE_USER_PROFILE_SUCCESS'),
  deleteUserProfileFailed: createAction(
    '[USER] DELETE_USER_PROFILE_FAILED',
    props<{ error: Error | HttpErrorResponse }>()
  ),

  getDevicesStart: createAction('[USER] GET_DEVICES_START'),
  getDevicesSuccess: createAction('[USER] GET_DEVICES_SUCCESS', props<{ devices: DeviceDetails[] }>()),
  getDevicesFailed: createAction('[USER] GET_DEVICES_FAILED', props<{ error: Error | HttpErrorResponse }>()),
  disableWebAuthNStart: createAction('[USER] DISABLE_WEBAUTHN_START', props<{ deviceId: string }>()),
  disableWebAuthNSuccess: createAction('[USER] DISABLE_WEBAUTHN_SUCCESS'),
  disableWebAuthNFailed: createAction('[USER] DISABLE_WEBAUTHN_FAILED', props<{ error: Error | HttpErrorResponse }>()),
  deleteDeviceStart: createAction('[USER] DELETE_DEVICE_START', props<{ deviceId: string }>()),
  deleteDeviceSuccess: createAction('[USER] DELETE_DEVICE_SUCCESS', props<{ deviceId: string; notice?: Notices }>()),
  deleteDeviceFailed: createAction('[USER] DELETE_DEVICE_FAILED', props<{ error: Error | HttpErrorResponse }>()),
  registerDevice: createAction('[USER] REGISTER_DEVICE', props<{ deviceDetails: UpdateDevice }>()),
  registerDeviceSuccess: createAction('[USER] REGISTER_DEVICE_SUCCESS', props<{ userJWS: string }>()),
  registerDeviceFailed: createAction('[USER] REGISTER_DEVICE_FAILED', props<{ error: Error }>()),

  updateUserName: createAction(
    '[USER] UPDATE_USER_PROFILE_FULL_NAME',
    props<{ name: { first_name: string; last_name: string } }>()
  ),

  updateUserEmail: createAction('[USER] UPDATE_USER_PROFILE_EMAIL', props<{ email: string }>()),
  emailVerificationComplete: createAction('[USER] EMAIL_VERIFICATION_COMPLETE'),
};
