import { Component, ChangeDetectionStrategy } from '@angular/core';
import { LanguageService, Languages } from '@core/services/language.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { NgClass } from '@angular/common';

@Component({
  selector: 'pbb-lang-toggle',
  templateUrl: './lang-toggle.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass, TranslateModule,MatButtonToggleModule],
})
export class LangToggleComponent {
  Languages = Languages;

  constructor(public translateService: TranslateService, public languageService: LanguageService) {}
}
