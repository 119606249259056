import { HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { finalize, mergeMap, Observable, throwError, timer } from 'rxjs';
const apis = [
  {
    type: 'getConsent',
    method: 'GET',
    path: '/payment-consents',
  },
  {
    type: 'getShippingCost',
    method: 'POST',
    path: '/shipping',
  },
  {
    type: 'grant',
    method: 'POST',
    path: '/payment-consents',
  },
  {
    type: 'register',
    method: 'POST',
    path: '/register',
  },
  {
    type: 'profileLookup',
    method: 'POST',
    path: '/user-profiles/profile-lookup',
  },
  {
    type: 'verifyEmail',
    method: 'PATCH',
    path: '/user-profiles/',
  },
  {
    type: 'webAuthnSession',
    method: 'POST',
    path: '/auth-session',
  },
  {
    type: 'webAuthnVerify',
    method: 'POST',
    path: '/verify-webauthn',
  },
];

// error redirect
const hiddenErrorApis = [
  { method: 'GET', path: '/logo/' },
  { method: 'GET', path: '/financial-institutions' },
];
const autoRedirectErrorStatusCodes: number[] = [600];

// retry attempt
// const excludedStatusCodes: number[] = [];
const statusRange: [number, number][] = [
  [404, 404],
  [500, 599],
];

const authErrorCodes = [401, 403];
const excludedAuthURLs = ['/check-trusted', '/pilot-otc'];
export const genericRetryStrategy =
  (maxRetryAttempts: number = 3, duration: number = 500, excludes: boolean = true) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error, i) => {
        const retryAttempt = i + 1;
        if (!excludes && retryAttempt < maxRetryAttempts) {
          return timer(duration);
        }
        if (
          retryAttempt >= maxRetryAttempts ||
          // excludedStatusCodes.find((e) => e === error.status) ||
          !statusRange.find((e) => error.status >= e[0] && error.status <= e[1])
        ) {
          return throwError(error);
        }
        return timer(duration);
      }),
      finalize(() => {})
    );
  };

export const untilDateRetryStrategy =
  (until: Date, duration: number = 500) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error) => {
        if (new Date() < until) {
          return timer(duration);
        }
        return throwError(error);
      }),
      finalize(() => {})
    );
  };

export const KEEP_ALIVE_STAGED_PROFILE_ERROR = 'STAGED_PROFILE';
export const keepAliveRetryStrategy =
  (duration: number = 500) =>
  (attempts: Observable<any>) => {
    return attempts.pipe(
      mergeMap((error) => {
        if (error === KEEP_ALIVE_STAGED_PROFILE_ERROR) {
          return timer(duration);
        }
        throw error;
      }),
      finalize(() => {})
    );
  };

export const getApiType = (req: HttpRequest<any>): string => {
  return apis.find((api) => api.method === req.method && req.url.includes(api.path))?.type || '';
};

export const isAutoRedirectError = (respError: HttpErrorResponse, reqMethod: string): boolean =>
  !!autoRedirectErrorStatusCodes.find((statusCode) => statusCode === respError.status) &&
  !hiddenErrorApis.find((api) => api.method === reqMethod && respError?.url?.match(api.path));

export const isAuthError = (respError: HttpErrorResponse): boolean =>
  !!authErrorCodes.find((statusCode) => statusCode === respError.status) &&
  !excludedAuthURLs.find((url) => respError?.url?.match(url));
