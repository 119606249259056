import { Inject, Injectable } from '@angular/core';
import { PBBEvents } from '@core/constants/events';
import { SNAME } from '@core/constants/storage';
import { Store } from '@ngrx/store';
import { errorOnSame } from '@core/utils';
import { BehaviorSubject, catchError, from, interval, map, Subject, Subscription } from 'rxjs';
import { setMerchantReferrer } from '@store/core/core.actions';
import { APPCONFIG } from '@core/tokens/configs';
import { APP_CONFIG } from '@core/interfaces/configs';
import { LoggerService } from '@core/services/logger.service';
import { Consent } from '@store/checkout/checkout.interface';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  _merchantWindow!: Window;
  _merchantURL!: string;
  heartbeatSub!: Subscription;
  heartBeatCounter!: number;
  initialized = false;
  public consentPayload!: Consent;
  public receviedFromMerchant: Subject<boolean> = new Subject();
  public tmxSessionId$: BehaviorSubject<string> = new BehaviorSubject('');
  logHB = !!sessionStorage.getItem('konekForceEnableLogHB');
  constructor(
    private readonly store: Store,
    public window: Window,
    private readonly document: Document,
    @Inject(APPCONFIG) private readonly appConfig: APP_CONFIG,
    private readonly logger: LoggerService
  ) {}

  init() {
    if (this.initialized) {
      return true;
    }

    this._merchantURL = sessionStorage.getItem(SNAME.MERCHANT) || this.document.referrer || '';

    if (!this._merchantURL) {
      this.logger.error('MERCHANT', 'NO MERCHANT OPENER WINDOW');
      return false;
    }

    this._merchantWindow = this.window.opener;

    this.store.dispatch(setMerchantReferrer({ merchantReferrer: this._merchantURL }));
    this.sendToMerchant(PBBEvents.konekLoaded);
    this.initialized = true;
    return this.receviedFromMerchant.asObservable();
  }

  sendToMerchant(event: PBBEvents, close = false, data?: any, log = true) {
    if (!this._merchantWindow) {
      this._merchantWindow = this.window.opener;
    }
    if (!this._merchantURL || !this._merchantWindow) {
      this.logger.error('MERCHANT', 'NO MERCHANT OPENER WINDOW');
      return;
    }
    if (log) {
      this.logger.log('MERCHANT', 'SENT', `${this._merchantURL} ${event}, ${data}`);
    }
    this._merchantWindow.postMessage(
      {
        event,
        data,
      },
      this._merchantURL
    );
    if (close) {
      this.window.close();
    }
  }

  fromMerchant(e: MessageEvent) {
    if (this._merchantURL?.includes(e.origin)) {
      if (!!e.data.correlationId) {
        sessionStorage.setItem(SNAME.CORRELATIONID, e?.data?.correlationId);
        this.receviedFromMerchant.next(true);
      }
      if (!!e.data.consentPayload) {
        this.logger.log('MERCHANT', 'RECEIVED CONSENT PAYLOAD', e.data);
        this.consentPayload = {
          secured: e.data.secured,
          ...e.data.consentPayload,
          creditor: {
            store: e.data.consentPayload.store,
          },
        };
        this.receviedFromMerchant.next(true);
      }

      if (!!e.data.heartBeatCounter) {
        this.handleHeartBeat(e.data.heartBeatCounter);
      }
      if (!!e.data.tmxSessionId) {
        this.logger.log('TMX', 'RECEIVED SESSIONID FROM SDK', e.data.tmxSessionId);
        this.tmxSessionId$.next(e.data.tmxSessionId);
      }
    }
  }

  handleHeartBeat(heartBeatCounter: number) {
    // pause heart beat: heartBeatCounter = -1
    if (!this.heartbeatSub && heartBeatCounter > 0) {
      this.logger.log('HEARTBEAT', `${heartBeatCounter === 1 ? 'START' : 'RESUME'} `);

      this.startHeartBeat();
    }
    this.heartBeatCounter = heartBeatCounter;
    if (this.logHB) {
      this.logger.log('HEARTBEAT', `${this.heartBeatCounter}`);
    }
    this.sendToMerchant(PBBEvents.konekHeartBeat, false, heartBeatCounter, false);
  }

  startHeartBeat() {
    this.heartbeatSub = from(interval(this.appConfig.hBReactionBuffer))
      .pipe(
        map(() => this.heartBeatCounter),
        errorOnSame,
        catchError(async () => {
          if (this.heartBeatCounter < 0) {
            return;
          }
          this.sendToMerchant(PBBEvents.konekWarning, true, '[HEARBEAT] STOPPED');
          this.logger.error('HEARBEAT', 'STOPPED');
        })
      )
      .subscribe();
  }
}
