export const PRIORITY_FORM_ERROR_TYPES = [
  'required',
  'pattern',
  'mask',
  'email',
  'postalCode',
  'min',
  'max',
  'maxlength',
  'minlength',
  'notregistered',
  'registered',
  'fullName',
  'invalidOTC',
];
