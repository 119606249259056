import { FormControl, ValidationErrors } from '@angular/forms';
export const CA_POSTAL_CODE_REGEX = /^(?!.*[DFIOQU])[A-VXY][\d][A-Z] ?[\d][A-Z][\d]$/;
export function EmailValidator(c: FormControl): ValidationErrors | null {
  // let EMAIL_REGEXP = new RegExp('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[A-Za-z]{2,4}$');
  // any charater except space is allowed using below.
  let EMAIL_REGEXP = /^[\S]+@[a-zA-Z0-9.-]+\.[A-Za-z]{2,4}$/;
  return EMAIL_REGEXP.test(c.value)
    ? null
    : ({
        email: {
          valid: false,
        },
      } as ValidationErrors);
}

export function PostalCodeValidator(c: FormControl): ValidationErrors | null {
  return checkPostalCodeFormat(c?.value)
    ? null
    : {
        postalCode: {
          valid: false,
        },
      };
}
export const checkPostalCodeFormat = (postalCode: string) => CA_POSTAL_CODE_REGEX.test(postalCode?.toUpperCase());

export function FullNameValidator(c: FormControl): ValidationErrors | null {
  return c.value?.trim().split(/ (.*)/).length > 1
    ? null
    : {
        fullName: {
          valid: false,
        },
      };
}
