/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.1
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { OrderItem } from './orderItem';

/**
 * Order details from the merchant for the consent
 */
export interface Order {
  /**
   * A unique value to identify the order or cart
   */
  merchant_order_ref: string;
  /**
   * A merchant assigned internal value that uniquely identifies a merchant\'s customer. This is useful to associate transactions to a specific customer for analytics and troubleshooting.
   */
  merchant_customer_ref?: string;
  /**
   * Placement mode
   */
  placement_mode: Order.PlacementModeEnum;
  /**
   * Indicates how the merchandise will be shipped to the customer.   * SINGLE - order fulfillment with single shipment   * SPLIT - order fulfillment with split shipments   * NONE - order fulfillment without shipmment
   */
  shipment_type: Order.ShipmentTypeEnum;
  /**
   * A list of order items and their recurring setups if available. Max 100 items permitted.
   */
  items: Array<OrderItem>;
}
export namespace Order {
  export type PlacementModeEnum = 'STANDARD' | 'EXPRESS';
  export const PlacementModeEnum = {
    Standard: 'STANDARD' as PlacementModeEnum,
    Express: 'EXPRESS' as PlacementModeEnum,
  };
  export type ShipmentTypeEnum = 'SINGLE' | 'SPLIT' | 'NONE';
  export const ShipmentTypeEnum = {
    Single: 'SINGLE' as ShipmentTypeEnum,
    Split: 'SPLIT' as ShipmentTypeEnum,
    None: 'NONE' as ShipmentTypeEnum,
  };
}
