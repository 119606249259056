import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AcquirerService } from './api/acquirer.service';
import { AdminService } from './api/admin.service';
import { ConsentManagementService } from './api/consentManagement.service';
import { CryptoService } from './api/crypto.service';
import { EmailVerificationService } from './api/emailVerification.service';
import { FinancialInstitutionService } from './api/financialInstitution.service';
import { KeepaliveService } from './api/keepalive.service';
import { KeyManagementService } from './api/keyManagement.service';
import { MerchantService } from './api/merchant.service';
import { PilotAllowlistingService } from './api/pilotAllowlisting.service';
import { UserAuthenticationService } from './api/userAuthentication.service';
import { UserDeviceManagementService } from './api/userDeviceManagement.service';
import { UserManagementService } from './api/userManagement.service';
import { WebAuthNService } from './api/webAuthN.service';

@NgModule({
  imports: [],
  declarations: [],
  exports: [],
  providers: [],
})
export class ApiModule {
  public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [{ provide: Configuration, useFactory: configurationFactory }],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: ApiModule, @Optional() http: HttpClient) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error(
        'You need to import the HttpClientModule in your AppModule! \n' +
          'See also https://github.com/angular/angular/issues/20575'
      );
    }
  }
}
