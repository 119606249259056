import { InjectionToken } from '@angular/core';
import { AuthConfig } from '@auth/authConfig';
import { APP_CONFIG, ENV_CONFIG } from '@core/interfaces/configs';
import { TmxService } from '@core/services/tmx.service';

export const ENVCONFIG = new InjectionToken<ENV_CONFIG>('ENVCONFIG');
export const APPCONFIG = new InjectionToken<APP_CONFIG>('APPCONFIG');

export const TMX = new InjectionToken<TmxService>('TMX');

export const AUTHCONFIG = new InjectionToken<AuthConfig>('AUTHCONFIG');
