import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(public window: Window) {}

  /**
   * Add an item to a localStorage() object
   * @param key The localStorage() key
   * @param value The localStorage() value
   */
  async set(key: string, value: any): Promise<any> {
    try {
      return this.window.localStorage.setItem(key, JSON.stringify(value));
    } catch (err) {
      return Promise.reject(err);
    }
  }

  /**
   * Get an item to a localStorage() object
   * @param key The localStorage() key
   */
  async get(key: string): Promise<any> {
    try {
      const val = await this.window.localStorage.getItem(key);
      if (val === null || val === undefined) {
        throw new Error('no value');
      } else {
        return JSON.parse(val);
      }
    } catch (err) {
      return null;
    }
  }

  async remove(key: string): Promise<void> {
    try {
      this.window.localStorage.removeItem(key);
      return;
    } catch (err) {
      return Promise.reject(err);
    }
  }
}
