import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { PRIORITY_FORM_ERROR_TYPES } from '@core/constants';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[pbb-form-field-error]',
  templateUrl: './pbb-form-field-error.component.html',
  standalone: true,
  imports: [TranslateModule],
})
export class PbbFormFieldErrorComponent {
  @Input() pbbFormControl!: FormControl;
  @Input() label!: string;

  getErrorLangKey(errors: any) {
    const errorKeys = Object.keys({ ...errors });
    const error = PRIORITY_FORM_ERROR_TYPES.find((err: any) => errorKeys.indexOf(err) > -1);
    if (error === 'maxlength') {
      return `PBB_FORM.ERROR_${error?.toUpperCase()}`;
    }
    return `PBB_FORM.${this.label?.toUpperCase()}_ERROR_${error?.toUpperCase()}`;
  }
}
