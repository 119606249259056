import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from '@core/services/storage.service';
import { UtilService } from '@core/services/util.service';
import { DOCUMENT } from '@angular/common';
import { WindowService } from '@core/services/window.service';

export const STORED_LANGUAGE_KEY = 'pbb-lang';
const URL_PARAM_KEY = 'lang';

export enum Languages {
  ENGLISH = 'en-CA',
  FRENCH = 'fr-CA',
}

export const LanguagesKey = {
  [Languages.ENGLISH]: 'EN-CA',
  [Languages.FRENCH]: 'FR-CA',
};

export const LanguagesKeyMapper = {
  en: Languages.ENGLISH,
  fr: Languages.FRENCH,
};

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  constructor(
    private readonly translateService: TranslateService,
    public window: Window,
    private readonly storageService: StorageService,
    @Inject(DOCUMENT) public document: Document,
    private readonly merchantWindowService: WindowService
  ) {}

  async getPreferredLanguage(): Promise<Languages> {
    const langKey = UtilService.GetUrlParam(URL_PARAM_KEY);
    if (langKey) {
      const lang = langKey.toUpperCase() === LanguagesKey[Languages.FRENCH] ? Languages.FRENCH : Languages.ENGLISH;
      await this.storePreferredLanguage(lang);
      return lang;
    }

    return (await this.storageService.get(STORED_LANGUAGE_KEY)) || Languages.ENGLISH;
  }

  async storePreferredLanguage(lang: Languages): Promise<void> {
    await this.storageService.set(STORED_LANGUAGE_KEY, lang);
  }

  setHTMLProp(lang: Languages = Languages.ENGLISH) {
    this.document.documentElement.lang = lang;
  }

  toggleLanguage(lang: Languages) {
    if (lang === this.translateService.currentLang) {
      return;
    }

    // pause heart beats
    this.merchantWindowService.handleHeartBeat(-1);
    this.storePreferredLanguage(lang).then(() => {
      const url = new URLSearchParams(this.window.location.search);
      url.set(URL_PARAM_KEY, lang);
      this.window.location.search = url.toString();
    });
  }
}
