/**
 * KONEK - CDS Server APIs v1.0
 * KONEK implemented APIs for consent management
 *
 * The version of the OpenAPI document: 1.3.0.1
 * Contact: developer@interac.ca
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface WebAuthNVerificationRequest {
  /**
   * WebAuthN authentication data, this could be base64 encoded assertion data
   */
  auth_code: string;
  /**
   * Device Identifier
   */
  device_ref: string;
  /**
   * Specify verification action to be performed.
   */
  verification_action: WebAuthNVerificationRequest.VerificationActionEnum;
}
export namespace WebAuthNVerificationRequest {
  export type VerificationActionEnum = 'REGISTER' | 'AUTHENTICATE';
  export const VerificationActionEnum = {
    Register: 'REGISTER' as VerificationActionEnum,
    Authenticate: 'AUTHENTICATE' as VerificationActionEnum,
  };
}
