import { Inject, Injectable } from '@angular/core';
import { ENV_CONFIG } from '@core/interfaces/configs';
import { ENVCONFIG } from '@core/tokens/configs';

@Injectable({
  providedIn: 'root',
})
export class LoggerService {
  constructor(@Inject(ENVCONFIG) private readonly envConfig: ENV_CONFIG) {}

  log(context: string, msg: string, data?: any) {
    if (this.envConfig.enableLogs) {
      console.log(`[${context || 'APP'}] ${msg}`, data || '');
    }
  }

  error(context: string, msg: string, data?: any) {
    if (this.envConfig.enableLogs) {
      console.error(`[${context || 'APP'}] ${msg}`, data || '');
    }
  }
}
