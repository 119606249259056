import { UserProfile } from '@api/model/userProfile';
import { IUserProfile } from '@store/common/user.interface';

export enum PBBEvents {
  consentPayloadError = 'consentPayloadError',
  createConsentFailed = 'createConsentFailed',
  popupBlocked = 'popupBlocked',
  konekAlreadyOpen = 'konekAlreadyOpen',
  konekLoaded = 'konekLoaded',
  konekLoadError = 'konekLoadError',
  konekCancelled = 'konekCancelled',
  konekError = 'konekError',
  konekWarning = 'konekWarning',
  konekClosed = 'konekClosed',
  konekCompleted = 'konekCompleted',
  konekHeartBeat = 'konekHeartBeat',
}

export interface SavePersonalDetailEvent {
  userInputs: Partial<IUserProfile>;
  isEmailChanged: boolean;
}
