import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState, FinancialInstitution, FooterConfig } from './core.interface';
import { OIDCDETAIL, OIDC_CODE, OIDC_ERROR } from '@core/constants/error-code';
import { SNAME } from '@core/constants/storage';
import { ModelError } from '@api/model/modelError';

export const selectCore = createFeatureSelector<CoreState>('core');

export const selectFinancialInstitutions = createSelector(
  selectCore,
  (state: CoreState) => state?.financialInstitutions
);

export const selectPartners = createSelector(selectCore, (state: CoreState) => {
  let fis = state?.financialInstitutions.data || [];
  return [
    {
      display_name: 'Interac',
      priority: true,
      logoURL: '/assets/images/interac.png',
    } as Partial<FinancialInstitution>,
    ...fis,
  ];
});
export const selectFinancialInstitution = (id: string) =>
  createSelector(selectCore, (state: CoreState) => state?.financialInstitutions?.data?.find((fi) => fi.id === id));
export const selectDeviceId = createSelector(selectCore, (state: CoreState) => state?.deviceId);
export const selectAttacheDeviceApi = createSelector(selectCore, (state: CoreState) => state?.attachDeviceApi);

export const selectFooterConfig = createSelector(selectCore, (state: CoreState): FooterConfig => state?.footerConfig);

export const selectHeaderConfig = createSelector(selectCore, (state: CoreState) => state?.headerConfig);
export const selectMerchantReferrer = createSelector(selectCore, (state: CoreState) => state?.merchantReferrer);

export const selectError = createSelector(
  selectCore,
  (state: CoreState) => state.error || ({ code: 'SYSTEM' } as ModelError)
);

export const selectFilteredAccountDisableReasons = createSelector(
  selectCore,
  (state: CoreState) => state.filteredAccountDisableReasons
);
export const selectSelectedFI = createSelector(selectCore, (state: CoreState) => state.selectedFI || {});
export const selectOIDCError = createSelector(selectCore, (state: CoreState) => {
  if (sessionStorage.getItem(SNAME.FORCE_SIGNOUT)) {
    sessionStorage.removeItem(SNAME.FORCE_SIGNOUT);
    return 'force_signout' as OIDC_ERROR;
  }
  return state?.OIDCError;
});
export const selectMaxShippingAddress = createSelector(
  selectCore,
  (state: CoreState) => state?.maxShippingAddress || -1
);
export const selectOIDCCode = createSelector(selectCore, (state: CoreState) => state?.OIDCCode);
export const selectOIDCCodeDetail = (key: string) =>
  createSelector(selectCore, (state: CoreState) => {
    if (!state.OIDCCode) {
      return null;
    }
    const detail: any = OIDC_CODE[state.OIDCCode];
    return detail[key] as OIDCDETAIL;
  });
export const selectTempemail = createSelector(selectCore, (state: CoreState) => state?.tempemail);
export const selectDeviceUpdate = createSelector(selectCore, (state: CoreState) => state.updateDeviceApi.data);
