<mat-button-toggle-group
  name="languageToggle"
  class="lang-toggle"
  id="lang-toggle-switch"
  (change)="
    languageService.toggleLanguage(
      translateService.currentLang === Languages.ENGLISH
        ? Languages.FRENCH
        : Languages.ENGLISH
    )
  "
>
  <mat-button-toggle
    value="{{ Languages.ENGLISH }}"
    [checked]="translateService.currentLang === Languages.ENGLISH"
    [aria-label]="'ARIA_LABEL.LANGUAGE' | translate"
    [disabled]="translateService.currentLang === Languages.ENGLISH"
    >EN</mat-button-toggle
  >
  <mat-button-toggle
    value="{{ Languages.FRENCH }}"
    [aria-label]="'ARIA_LABEL.LANGUAGE' | translate"
    [checked]="translateService.currentLang === Languages.FRENCH"
    [disabled]="translateService.currentLang === Languages.FRENCH"
    >FR</mat-button-toggle
  >
</mat-button-toggle-group>